@import "../../lib/styles.scss";

$max-width-content: 40rem;

.SectionView3 {
  width: 100%;
  margin: auto;
  text-align: left;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &.light {
    background: white;
    color: $theme-offBlack;
  }

  &.dark {
    background: $theme-offBlack;
    color: white;
  }

  &.detail {
    position: relative;
    &.fixed-section {
      padding-bottom: 150vh;
    }

    .detail-content3 {
      padding: 4rem 3rem;
      padding-left: 10%;
      font-family: "Denmark";
      line-height: 1.6rem;
      font-size: 1.5rem;
      line-height: 2.7rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      padding-top: 13rem;
      .text {
        width: 300px;
        z-index: 500;
        width: 78%;
        min-width: 37rem;
        margin-top: 9rem;
        max-width: 65rem;
      }
      .images {
        width: 100%;
        max-width: 65rem;
        height: 300px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media only screen and (max-width: 670px) {
    min-height: 100vh;
    height: unset;
    
    &.detail {
      .detail-content3 {
        line-height: 2rem;
        font-size: 1.1rem;
        flex-direction: column;

        padding: 2rem;
        // padding-top: 2rem;
        font-size: 1.2rem;
        .text {
          min-width: unset;
          padding-bottom: 2rem;
          width: unset;
        }
        .images {
          width: 100%;
        }
      }
    }
  }
}
