@import "../../lib/styles.scss";

.LandingView {
  scroll-behavior: smooth;
  background: black;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0rem;
  left: 0;
  bottom: 0;
  // height: 200vh;
  // height: 100%;
  font-family: "Allzweck";
  button {
    outline: unset !important;
  }

  .button-toggle-view {
    position: fixed;
    top: 50vh;
    left: .8rem;
    transform-origin: left;
    z-index: 300;
    background: $theme-yellow;
    width: 108px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    padding-top: 1rem;
    color: $theme-offBlack;
    // color: rgb(78, 78, 78);
    text-transform: uppercase;
    cursor: pointer;
    font-size: 0.8rem;
    // opacity: .4;
    transition: all 0.2s ease-out;
    .text {
      transition: all 0.2s ease-out;
      transform: scale(1);
    }
    
    &:hover,
    &:active {
      opacity: 1;
      transform: rotate(-90deg);;
      color: $theme-offBlack;
      .text {
        transform: scale(1.05);
      }
    }
    transform: rotate(-90deg);
    transition: all 0.2s ease-out;
    
  }

  &.scroll-up {
    cursor: url("https://img.icons8.com/ios/50/000000/circled-chevron-up.png"),
      default;
  }

  &.scroll-down {
    cursor: url("https://img.icons8.com/ios/50/000000/circled-chevron-down.png"),
      default;
  }

  &.custom-pointer {
  }

  .progress-bar {
    position: absolute;
    right: 0;
    top: 0rem;
    left: 0;
    border: 2px solid $theme-yellow;
    // height: 100vh;
    z-index: 500;
  }

  button {
    outline: -webkit-focus-ring-color auto 0px;
  }

  .up {
    position: absolute;
    top: 0vh;
  }

  .down {
    position: absolute;
    bottom: 0vh;
  }

  .middown {
    position: absolute;
    top: 20vh;
  }


  
  .main-title-container {
    position: fixed;
    top: 0rem;
    left: 0;
    right: 0;
    z-index: 60;
    padding: 2rem 4rem;
    display: flex;
    justify-content: flex-start;
    width: 50%;
    max-width: $max-ui-width;

    

    .title {
      max-width: 35rem;
      color: white;
      font-size: 44px;
      // position: absolute;
      top: 2rem;
      width: 100%;
      max-width: $max-ui-width;
      
      // left: 2rem;
      text-align: left;
      text-transform: uppercase;
      &.black {
        color: $theme-offBlack;
      }

      .yellow {
        color: $theme-yellow;
      }
      .gray {
        color: $theme-offBlack;
      }
    }
  }
  .view-height {
    width: 10px;
    position: absolute;
    left: 0;
    top: 0;
    background: $theme-yellow;
    z-index: 1100;
    display: flex;
    align-items: flex-end;
  }
  .view {
    // border: 3px solid pink;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transition: opacity 1s ease-in-out;

    
  }
  .instructions {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 500;
  }
  .videoitem {
    background: black;
    video {
      height: 100%;
    }
  }

  .actions-group {
    position: fixed;
    bottom: -15rem;
    left: 0;
    right: 0;
    z-index: 200;
    // height: 6rem;
    padding: 1rem 4rem;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    transition: bottom 3.3s ease-in-out;
    max-width: $max-ui-width;
    margin: auto;
    &.actions-up {
      bottom: 0;
    }
  }

  .view-navigation-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    // border: 2px solid $theme-yellow;
    color: $theme-yellow;
    padding: 1rem 2rem;
    width: 40vw;
    .view-name {
      text-transform: uppercase;
    }
    .arrow {
      transition: transform 0.3s ease-out;
      transform: scale(1);
      display: flex;
      &:active {
        transform: scale(1.2);
      }
      &:hover {
        transform: scale(1.2);
      }
      cursor: pointer;
      svg {
        width: 15px;
        height: auto;
      }
    }
    .action-title {
      color: white;
      font-weight: 400;
      // width: 200px;
      text-align: center;
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      transition: all 0.7s ease-in-out;
      &.black {
        color: black;
      }
    }
    .change-view-button {
      background-color: transparent;
      border: 3px solid white;
      border-radius: 50px;
      width: 70px;
      height: 70px;
      transition: background 1s ease-out;
      transition: transform 0.3s ease-in-out;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.black {
        border: 3px solid black;
        &:hover {
          transform: scale(1.1);
        }
        .filling {
          background: black !important;
        }
      }

      .filling {
        transition: "all 1.3s ease-in-out";
      }
    }
  }

  .landing-action {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    text-transform: unset;
    font-family: "Denmark";
    z-index: 1000;

    &.hidden {
      min-width: 55px;
    }
    svg {
      height: 70px;
      width: 70px;
    }

    &.wider {
      width: 45%;
      margin-bottom: 1.5rem;
    }

    .next-view {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .action-title {
        color: white;
        font-weight: 400;
        // width: 200px;
        text-align: center;
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
        transition: all 0.7s ease-in-out;
        &.black {
          color: black;
        }
      }
      .change-view-button {
        background-color: transparent;
        border: 3px solid white;
        border-radius: 50px;
        width: 70px;
        height: 70px;
        transition: background 1s ease-out;
        transition: transform 0.3s ease-in-out;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.black {
          border: 3px solid black;
          &:hover {
            transform: scale(1.1);
          }
          .filling {
            background: black !important;
          }
        }

        .filling {
          transition: "all 1.3s ease-in-out";
        }
      }
    }

    .navigation-button {
      width: 70px;
      height: 70px;
      border-radius: 50px;

      color: white;
      font-size: 0.8rem;
      font-family: "Denmark";
      border: 0px transparent;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background: rgba(0, 0, 0, 0);
      margin-top: -2.5rem;
      margin-left: 2.5rem;

      .menu-mobile {
        svg {
          width: 7px;
          height: auto;
        }
      }

      .menu-desktop {
        svg {
          width: 40px;
          height: auto;
        }
      }

      &:hover {
        transform: scale(1.1);
      }

      &:active {
        // transform: scale(1.1);
      }
    }
  }
  .menu-landing {
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 50px;
    text-transform: unset;
    font-family: "Denmark";
    z-index: 1000;

    .navigation-button {
      width: 70px;
      height: 70px;
      border-radius: 50px;

      color: white;
      font-size: 0.8rem;
      font-family: "Denmark";
      border: 0px transparent;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background: rgba(0, 0, 0, 0);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -0.5rem;
      // margin-left: 5%;
      .menu-mobile {
        // margin-top: -0.5rem;
        top: 0;
        right: 0;
        svg {
          width: 70px;
          height: auto;
        }
      }

      .menu-desktop {
        margin-left: 5rem;
        svg {
          width: 35px;
          height: auto;
        }
      }

      &:hover {
        transform: scale(1.1);
      }

      &:active {
        // transform: scale(1.1);
      }
    }
  }

  .mobile-title {
    display: none;
  }
  .desktop-title {
    display: block;
  }

  .menu-mobile {
    display: none;
  }

  .menu-desktop {
    display: block;
  }

  @media only screen and (min-width:640px) and (max-width: 850px) and (orientation: landscape)  {
    .main-title-container {
      padding: 2rem 4rem;
      padding-top: 2rem;
      // transition: opacity .5s ease-in;
      .title {
        font-size: 26px;
        
      }
    }
  }

  @media only screen and (max-width: 670px) {
    .view-navigation-actions {
      width: 90vw;
    }
    .main-title-container {
      padding: 2rem 1rem;
      padding-top: 90px;
      // transition: opacity .5s ease-in;
      .title {
        font-size: 26px;
        
      }
    }
    .actions-group {
      padding: 1rem;
    }
    .mobile-title {
      display: block;
    }
    .desktop-title {
      display: none;
    }
    .menu-mobile {
      display: block;
      // margin-right: 4rem;
    }

    .menu-desktop {
      display: none;
    }
    .videoitem {
      background: black;
      position: absolute;
      top: 0;
      // left:-19rem;
      right: 0;
      bottom: 0;
      -webkit-overflow-scrolling: touch;
      // scroll-behavior: smooth;
      width: auto;
      height: 100%;
      video {
        height: 100%;
      }
    }
  }

  @media only screen and (max-width: 375px) {
    .gradient-top {
      opacity: 1;
      transition: opacity .5s ease-in;
      
      &.hide {
        z-index: 0;
        opacity: 0;
      }
    }

    .main-title-container {
      padding: 2rem 1rem;
      padding-top: 90px;
      transition: all .5s ease-in;
      &.hide {
        z-index: 0;
      }
      .title {
        font-size: 26px;
        opacity: 1;
        transition: all .5s ease-in;
        &.hide {
          z-index: 0;
          opacity: 0;
        }
      }
    }

  }
}
