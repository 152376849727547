@import "../../lib/styles.scss";
.StickyInnerContainer {
    position: sticky;
    top: 0;
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    background: pink;

    &.light {
        background: white;
        color: $theme-offBlack;
      }

      &.dark {
        background: $theme-offBlack;
        color: white;
      }
}