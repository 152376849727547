.SectionView {
    width: 100%;
    height:auto;
    .back {
        position: absolute;
        top: 3rem;
        left: 1rem;
        z-index: 300;
        cursor: pointer;
        svg {
            width: 60px;
        }
    }
}

