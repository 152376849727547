@import "../../lib/styles.scss";

$max-width-content: 40rem;

.SectionView5 {
  width: 100%;
  margin: auto;
  text-align: left;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;

  .Section5_ {
    position: relative;
    width: 100%;
    min-height: 100vh;

    
  }

  &.centered {
    justify-content: center;
    align-items: center;
  }

  &.sticky {
    // padding-bottom: 180vh !important;
  }
  &.uncentered {
    justify-content: center;
  }

  &.light {
    background: white;
    color: $theme-offBlack;
  }

  &.dark {
    background: $theme-offBlack;
    color: white;
  }

  &.detail {
    position: relative;
    &.fixed-section {
      padding-bottom: 400vh;
    }
    .detail-content5 {
      height: 33rem;
      // padding: 4rem 3rem;
      // padding-left: 10%;
      font-family: "Denmark";
      line-height: 1.6rem;
      position: absolute;
      margin: auto;
      display: flex;
      font-size: 1.5rem;
      line-height: 2.7rem;

      .images {
        position: relative;
        height: 100%;
        width: auto;
      }
    }
  }

  &.single-img {
    .ImagesZoom {
      width: 70%;
      margin: auto;
    }
  }

  @media only screen and (min-width: 640px) and (max-width: 850px) and (orientation: landscape) {
    height: unset;
    width: 100vw;
    padding: 6rem 0;
    min-height: unset;
    display: flex;
    justify-content: center;
    align-items: center;

    &.single-img {
      // min-height: 20vh;
      padding: 1rem 0;
      .ImagesZoom {
        margin: 0;
        margin: auto;
        margin-bottom: 0 !important;
        width: 90%;

        .ImageWithFooter {
          margin-bottom: 0rem !important;
        }
      }
    }
  }

  @media only screen and (max-width: 670px) {
    height: unset;
    width: 100vw;
    padding: 6rem 0;
    min-height: unset;
    display: flex;
    justify-content: center;
    align-items: center;

    &.single-img {
      min-height: 30vh;
      .ImagesZoom {
        margin: 0;
        margin: auto;
        margin-bottom: 0 !important;
        width: 90%;

        .ImageWithFooter {
          margin-bottom: 0rem !important;
        }
      }
    }
  }
}
