@import "../../lib/styles.scss";
.TopicDetailView {
  width: 100%;
  background: black;
  // overflow-x: hidden;
  position: absolute;
  // top: 0;
  // left: 0;
  // overflow-y: scroll;
  // transition: all 1s ease-in-out;

  img {
    overflow: hidden;
  }

  .up {
    position: absolute;
    top: 0;
  }

  .down {
    position: absolute;
    top: 0;
  }

  .arrow-back {
    // position: fixed;
    top: 0;
    left: 0;

    // display: flex;
    // align-items: center;
    // justify-content: center;
    z-index: 1000;
    cursor: pointer;
    // background: $theme-yellow;
    padding: 0rem;
    border-radius: 50px;

    svg {
      width: 60px;
      height: auto;
      transform: scale(1);
      transition: transform 0.2s ease-out;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .back {
    position: absolute;
    top: 3rem;
    left: 1rem;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 300;
    cursor: pointer;
    // background: $theme-yellow;
    padding: 0rem;
    border-radius: 50px;
    svg {
      width: 60px;
    }
  }

  @media only screen and (max-width: 800px) {
    overflow: hidden;
    .back {
      top: 0.5rem;
      left: 0.5rem;
      width: 50px;
      height: 50px;
    }
  }
}
