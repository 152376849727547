@import "../../lib/styles.scss";
.DetailFooter {
  height: 18rem;
  background: $theme-offBlack;
  // padding-top: 3rem;
  // background: $theme-yellow;
  font-family: "Denmark";
  position: relative;
  // border-top: 1px solid gray;

  .footer-mobile {
    display: none;
  }

  .footer-desktop {
    display: block;
  }

  .link-copied {
    position: absolute;
    width: 100vw;
    bottom: 0;
    color: #dddddd;
    margin: auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
  }

  .share-article {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 50%;
    color: white;
    .share-article-content {
      width: 60%;
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .links {
        display: flex;
        flex-direction: row;
      }
      .share-link {
        margin: 0 0.5rem;
        p {
          text-decoration: underline;
          margin: 0;
        }

        cursor: pointer;
      }
    }
  }

  .change-topics {
    position: absolute;
    top: 10%;
    width: 100%;

    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;

    text-align: center;

    .up-container {
      margin: auto;
      .title {
        margin-bottom: 1rem;
      }
      svg {
        width: 70px;
        height: auto;
        cursor: pointer;
        transform: scale(1);
        transition: transform 0.2s ease-out;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .action-container {
    position: absolute;
    bottom: 9rem;
    left: 0;
    right: 0;
    margin: 0 2rem;
    // padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    height: 9rem;
    .action1 {
      width: 20%;
      display: flex;
      &:last-child {
        justify-content: flex-end;
      }
      .topic-block {
        display: flex;
        text-transform: uppercase;
        font-family: "Allzweck";
        color: $theme-yellow;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .text {
          margin: 0 2rem;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .action2 {
      width: 60%;
      display: flex;
      align-items: center;
      .share-article-content {
        color: white;
        width: 60%;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .links {
          display: flex;
          flex-direction: row;
        }
        .share-link {
          margin: 0 0.5rem;
          p {
            text-decoration: underline;
            margin: 0;
          }

          cursor: pointer;
        }
      }
    }
  }
  .footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 9rem;
    margin: auto;
    font-size: .9rem;
  }
  .media-footer {
    margin: 0rem 2rem;
    height: 100px;
    border-top: 1px solid #bcbcbb;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1.5rem;
    
    a,
    div {
      color: #bcbcbb;
      text-decoration: none;
      cursor: pointer;
    }
    .links1,
    .links2,
    .links3 {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      width: 33.3%;
      .link {
        margin-bottom: 1rem;
        .small-svg {
          svg {
            width: 150px;
            height: auto;
          }
        }
      }
    }
    .links3 {
      align-items: flex-end;
    }
    .links2 {
      align-items: center;
    }
    .links-block {
      justify-content: space-around;
      svg {
        width: 24px;
      }
    }
    .link {
      svg {
        width: 200px;
        height: auto;
      }
    }
    .links-block {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 1rem;
      .media-link {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;

        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    height: 55vh;
    width: 100vw;
    .footer-mobile {
      display: flex;
      align-items: flex-end;
    }

    .footer-desktop {
      display: none;
    }
    .link-copied {
      bottom: 11rem;
      color: gray;
      // top: 2%;
    }
    .topic-title {
      font-size: 2rem;
      top: 6rem;
      left: 6%;
    }

    .share-article {
      top: unset;
      padding-bottom: 1rem;
      top: 7%;
      .share-article-content {
        .share {
          margin-bottom: 0.5rem;
        }
        width: 90%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .change-topics {
      // top: 45% !important;
      width: 100vw;
      display: flex;
      padding: 0;
      svg {
        width: 15px;
        height: auto;
      }
      .topic-block {
        display: flex;
        text-transform: uppercase;
        font-family: "Allzweck";
        color: $theme-yellow;
        align-items: center;
        justify-content: center;
        padding: 0 3rem;
        cursor: pointer;
        .text {
          margin: 0 2rem;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .topic-block {
        width: 50%;
        padding: 0;
        justify-content: unset;
        align-items: unset;
        &:first-child {
          padding-left: 2rem;
          justify-content: flex-start;
        }

        &:last-child {
          padding-right: 2rem;
          justify-content: flex-end;
        }
        .icon {
          margin: auto;
          margin-left: 0;
          margin-right: 0;
        }
        .text {
          font-size: 0.75rem;
          margin: 0 1rem;
          display: flex;
          // justify-content: center;
          align-items: center;
        }
      }
    }
    .footer-container {
      height: unset;
      .media-footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 400px;
        .links1,
        .links2,
        .links3 {
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        .link {
          svg {
            margin: auto;
            width: 200px;
            height: auto;
          }
        }

        .links-block {
          flex-direction: column;
          .media-link {
            margin: auto;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 640px) and (max-width: 850px) and (orientation: landscape) {
    height: 67vh;
    width: 100vw;
    .footer-mobile {
      display: flex;
      align-items: flex-end;
    }

    .footer-desktop {
      display: none;
    }
    .link-copied {
      bottom: 11rem;
      color: gray;
      // top: 2%;
    }
    .topic-title {
      font-size: 2rem;
      top: 6rem;
      left: 6%;
    }

    .share-article {
      top: unset;
      padding-bottom: 1rem;
      top: 7%;
      .share-article-content {
        .share {
          margin-bottom: 0.5rem;
        }
        width: 90%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .change-topics {
      // top: 45% !important;
      width: 100vw;
      display: flex;
      padding: 0;
      svg {
        width: 15px;
        height: auto;
      }
      .topic-block {
        display: flex;
        text-transform: uppercase;
        font-family: "Allzweck";
        color: $theme-yellow;
        align-items: center;
        justify-content: center;
        padding: 0 3rem;
        cursor: pointer;
        .text {
          margin: 0 2rem;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .topic-block {
        width: 50%;
        padding: 0;
        justify-content: unset;
        align-items: unset;
        &:first-child {
          padding-left: 2rem;
          justify-content: flex-start;
        }

        &:last-child {
          padding-right: 2rem;
          justify-content: flex-end;
        }
        .icon {
          margin: auto;
          margin-left: 0;
          margin-right: 0;
        }
        .text {
          font-size: 0.75rem;
          margin: 0 1rem;
          display: flex;
          // justify-content: center;
          align-items: center;
        }
      }
    }
    .media-footer {
      font-size: .85rem;
    }
    .links-block {
      .media-link {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 .25rem;
      }
      .links1 {
        svg {
          width: 180px;
        }
      }
      
    }
    
  }

  @media only screen and (max-width: 670px) {
    height: 75vh;
    .share-article {
      top: unset;
    }
    
  }

 
}
