@import "../../lib/styles.scss";

$max-width-content: 40rem;

.DetailSection {
  width: 100vw;
  transition: all 0.3s ease-in-out;
  // overflow: hidden;

  .section-block-1, .section-block-2, .section-block-3 {
    &.invert {
      .SectionView4 {
        &.dark {
          background: white !important;
          color: $theme-offBlack !important;
        }
        &.light {
          background: $theme-offBlack !important;
          color: white !important;
        }
      }
      .SectionView5 {
        &.dark {
          background: white !important;
          color: $theme-offBlack !important;
        }
        &.light {
          background: $theme-offBlack !important;
          color: white !important;
        }
      }
      .SectionView7 {
        &.dark {
          background: white !important;
          color: $theme-offBlack !important;
        }
        &.light {
          background: $theme-offBlack !important;
          color: white !important;
        }
      }
      .SectionView6 {
        &.dark {
          background: white !important;
          color: $theme-offBlack !important;
        }
        &.light {
          background: $theme-offBlack !important;
          color: white !important;
        }
      }
    }
  }

  .section-block-2 {
    &.true {
      .SectionView3 {
        &.dark {
          background: white !important;
          color: $theme-offBlack !important;
        }
        &.light {
          background: $theme-offBlack !important;
          color: white !important;
        }
      }
    }
  }
  .section-block-3 {
    // .SectionView4 {
    //   &.dark {
    //     background: white !important;
    //     color: $theme-offBlack !important;
    //   }
    //   &.light {
    //     background: $theme-offBlack !important;
    //     color: white !important;
    //   }
    // }
  }

  .extra-text {
    position: absolute;
    top: 5rem;
    background: yellow;
  }
}
