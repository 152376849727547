.DetailHeader {
  height: 90vh;
  background: black;
  font-family: "Allzweck" !important;

  .centerX {
    position: absolute;
    left: 900px;
    height: 100vh;
    width: 2px;
    top: 0;
    z-index: 2000;
    // background: pink;
  }

  .topic-title {
    position: absolute;
    top: 4rem;
    left: 10%;
    text-transform: uppercase;
    color: white;
    font-size: 100px;
    width: 50%;
    text-align: left;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    .text {
      background: black;
      padding: 0 12px;
      // line-height: 87px;
    }
  }

  // hide scrollbars
  .bg-img-container::-webkit-scrollbar {
    display: none;
  }
  .bg-img-container {
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    height: 90vh;
    overflow-x: scroll;
    overflow-y: hidden;
    ::-webkit-scrollbar {
      display: none !important;
    }

    -ms-overflow-style: none !important; /* Internet Explorer 10+ */
    scrollbar-width: none !important;
  }
  .bg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0rem;
    right: 0;
    overflow: hidden;
    object-fit: cover;
    width: 100vw;
    height: 90vh;
  }

  @media only screen and (max-width: 1400px) and (orientation: portrait) {
    .topic-title {
      font-size: 70px;
      top: 9rem;
      left: 6%;
      .text {
        background: black;
        padding: 0.3rem;
      }
    }
    .bg-img {
      width: auto;
      height: 100vh;
    }
  }

  // landscape phones
  @media only screen and (min-width:640px) and (max-width: 850px) and (orientation: landscape)  {
    .topic-title {
      font-size: 2rem;
      top: 5rem;
      left: 6%;
      .text {
        background: black;
        padding: 0.3rem;
      }
    }
    .bg-img-container {
      object-fit: cover;
    }
  }

  //landscape ipad
  @media only screen and (min-width:900px) and (max-width: 1024px) and (orientation: landscape)  {
    .topic-title {
      font-size: 5rem;
      top: 5rem;
      left: 6%;
      .text {
        background: black;
        padding: 0.3rem;
      }
    }
    .bg-img-container {
      object-fit: cover;
    }
  }

  @media only screen and (max-width: 670px) {
    .topic-title {
      font-size: 2rem;
      top: 9rem;
      left: 6%;
      .text {
        background: black;
        padding: 0.3rem;
      }
    }
  }
}
