@import '../../lib/styles.scss';
.MediaFooter {
  height: 20vh;
  font-family: "Denmark" ;
  position: relative;

  .scroll-up {
    position: absolute;
    top: 65%;
    width: 100vw;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    text-align: center;
    .up-container {
      margin: auto;
      .title {
        margin-bottom: 1rem;
      }
      svg {
        width: 70px;
        height: auto;
        cursor: pointer;
      }
    }

  }
  .footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .media-footer {
    // font-size: .9rem;
    margin: 2rem;
    height: 100px;
    border-top: 1px solid #BCBCBB;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    align-items: flex-start;
    
    // padding-top: 1rem;
    a, div {
      color: #BCBCBB;
      text-decoration: none;
      cursor: pointer;
    }
    .links3 {
      div {
        margin: 0;
        display: flex;
        flex-direction: row;
        // font-size: .9rem;
      }
      .link {
        margin-bottom: 0.3rem !important;
      }
    }
    
    .links1 {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      .link {
        margin-bottom: 1rem;
      }
    }

    .links3 {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      font-size: .9rem;
      .link {
        margin-right: 1rem;
        margin-top: -1rem;
      }
    }

    $animate: all 0.01s ease-in-out;

    
    .links2 {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 1rem;
      .media-link {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;

        .example {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
        align-items: center;
          .hover {
            // font-size: 36px;
            // text-align: center;
            // margin: 0 auto;
            // padding: 0;
            transition: $animate;
            position: relative;
            display: flex;
            justify-content: center;
        align-items: center;
            &:before,
            &:after {
              content: "";
              position: absolute;
              bottom: -7px;
              width: 0px;
              height: 1px;
              margin: 5px 0 0;
              transition: $animate;
              transition-duration: 0.25s;
              opacity: 0;
              background-color: darken($theme-offWhite,5%);
            }
            &.hover-1 {
              &:before,
              &:after {
                left: 0;
              }
            }
          }
          &:hover {
            cursor: pointer;
            .hover {
              &:before,
              &:after {
                width: 100%;
                opacity: 1;
              }
              &.hover-3,
              &.hover-4 {
                &:before,
                &:after {
                  width: 50%;
                }
              }
            }
          }
        }


        
        
        
        
        svg {
          margin-right: .5rem;
        }
      }
    }
  }

  @media only screen and (min-width:640px) and (max-width: 850px) and (orientation: landscape)  {
    height: fit-content;
    .topic-title {
      font-size: 2rem;
      top: 6rem;
      left: 6%;
    }
    .scroll-up {
      top: 30% !important;
    }
    .footer-container {
      position: unset;
      font-size: .8rem;
      .media-footer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 1rem;
        width: unset;
        padding-top: 0;
        
        // .links1, .links3 {
        //   justify-content: center;
        //   align-items: center;
        // }
        .link3s {
          padding-bottom:2rem;
        }
        .links2 {
          margin-bottom: .5rem;
          margin-top:1rem;
          justify-content: space-between;
          width: 100%;
          flex-direction: column;
          .media-link {
            margin: auto;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 670px) {
    height: fit-content;
    .topic-title {
      font-size: 2rem;
      top: 6rem;
      left: 6%;
    }
    .scroll-up {
      top: 30% !important;
    }
    .footer-container {
      position: unset;
      font-size: .8rem;
      .media-footer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 1rem;
        width: unset;
        // .links1, .links3 {
        //   justify-content: center;
        //   align-items: center;
        // }
        .links2 {
          margin-bottom: .5rem;
          margin-top:1rem;
          justify-content: space-between;
          width: 100%;
          .media-link {
            margin: auto;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
  

