@import "../../lib/styles.scss";

.LoadingView {
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Denmark";
  font-weight: 200;
  overflow: scroll;
  // text-transform: uppercase;

  .columns {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    // bottom: 0;
    min-height: 100vh;
    width: 100vw;
    .column1 {
      border-right: 3px solid white;
      width: 33%;
      .block1 {
        height: 75%;
        border-bottom: 3px solid white;
      }
    }
    .column2 {
      border-left: 3px solid white;
      width: 33%;
      .block2 {
        height: 12%;
        border-bottom: 3px solid white;
      }
      .block3 {
        height: 75%;
        border-bottom: 3px solid white;
      }
    }
    .text-description {
      width: 34%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 636px;
      
      margin: auto;
      top: 11%;
      font-size: 22px;
      line-height: 41.02px;
      .block-text {
        border-bottom: 3px solid white;
        padding: 1.5rem;
        &.second {
          border-bottom: unset;
        }
      }
      
    }
    .btn-skip {
      margin-top: 2rem;
      height: 65px;
      width: 220px;
      left: 88px;
      top: 613px;
      border-radius: 0px;
      background: $theme-yellow;
      color: $theme-offBlack;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: "Allzweck";
      margin-bottom: 2rem;
      &.unactive {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }

  

  .progress-bar-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    z-index: 100;
    background: rgba(248, 248, 248, 0.205);

    .progress-bar {
      height: 100%;
      background: $theme-yellow;
    }
  }
  @media only screen and (max-width: 1050px) {
    .columns {
      .column1 {
        width: 20%;
      }
      .column2 {
        width: 20%;
      }
      .text-description {
        width: 60%;
      }
    }
  }

  @media only screen and (max-width: 870px) {
    .columns {
      .column1 {
        width: 20%;
      }
      .column2 {
        width: 20%;
      }
      .text-description {
        width: 60%;
      }
    }
  }
  
  @media only screen and (max-width: 670px) {
    overflow: scroll;
    .columns {
      .column1 {
        width: 5%;
      }
      .column2 {
        width: 5%;
      }
      .text-description {
        width: 90%;
        top: 6%;
        font-size: 20px;
        line-height: 33.02px;
        .block-text {
          padding: 5%;
        }
      }
      .btn-skip {
        margin-top: -.5rem;
      }
    }
  }
  @media only screen and (max-width: 400px) {
    overflow: scroll;
    .columns {
      .text-description {
        width: 90%;
        top: 3%;
        .block-text {
          padding: 5%;
        }
      }
    }
  }

  @media only screen and (max-width: 375px) {
    overflow: scroll;
    .text-description {
      top: 2%;
      font-size: 20px;
      line-height: 32.02px;
    }
    .btn-skip {
      margin-top: 0;
    }
  }

  @media only screen and (min-width:640px) and (max-width: 850px) and (orientation: landscape)  {
    .text-description {
      top: 15%;
      font-size: 1.5rem;
      line-height: 180%;
    }
    .btn-skip {
      margin-top: 0.5rem;
    }
  }
}

.yellow {
  color: $theme-yellow;
}
