.ImagesHorizontalCarousel {
  height: 100%;
  // height: 600px;
  min-height: 250px;
  overflow: hidden;
  position: absolute;
  // top: 0;
  display: flex;
  // margin-left: 30%; //delete this
  padding-left: 20rem;
  .img-carousel,
  .video-asset {
    width: auto;
    height: 100%;
    margin-right: 2rem;
  }

  @media only screen and (max-width: 1300px) {
    padding-left: 30rem;
  }

  @media only screen and (max-width: 800px) {
    padding-left: 0rem;
    .img-carousel {
      height: 250px;
      width: auto;
      margin-right: 2rem;
    }
  }
}

.ImagesHorizontalCarouselScrollMobile {
  padding-top: 5rem;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 85%;
  margin: auto;

  .vertical-img-mobile {
    .ImageWithFooter {
      margin-bottom: 5rem;
    }
  }
  @media only screen and (max-width: 670px) {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 85%;
    margin: auto;

    .vertical-img-mobile {
      .ImageWithFooter {
        margin-bottom: 5rem;
      }
    }
  }
}
