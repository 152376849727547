@import "../../lib/styles.scss";

$max-width-content: 40rem;

.SectionView1 {
  width: 100%;
  margin: auto;
  text-align: left;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  // padding-bottom: 400vh;

  &.centered {
    padding-bottom: 100vh !important;
  }

  &.light {
    background: white;
    color: $theme-offBlack;
    .text-section {
      background: rgba(255, 255, 255, 0.548);
    }
  }

  &.dark {
    background: $theme-offBlack;
    color: white;
    .text-section {
      background: #25252562;
    }
  }

  .text-section {
    padding: 1rem;
  }
  &.detail {
    position: relative;
    &.fixed-section {
      padding-bottom: 300vh;
    }
    .detail-content1 {
      // padding: 4rem 3rem;
      // padding-left: 10%;
      // font-family: "Denmark";
      // line-height: 1.6rem;
      // position: absolute;
      // margin: auto;
      // display: flex;
      // font-size: 1.5rem;
      // line-height: 2.7rem;
      padding: 4rem 3rem;
      padding-left: 10%;
      font-family: "Denmark";
      line-height: 1.6rem;
      font-size: 1.5rem;
      line-height: 2.7rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      .text {
        z-index: 500;
        margin-top: 1rem;
        width: 100%;
        display: flex;
        &.row {
          flex-direction: row;
          .text-paragraph {
            &:first-child {
              margin-right: 7%;
            }
          }
        }
        &.column {
          flex-direction: column;
          width: 50%;
          margin: auto;
          .text-paragraph {
            &:first-child {
              margin-bottom: 7%;
            }
          }
        }
        justify-content: space-between;
      }
      
      .text-block {
        width: 300px;
        z-index: 500;
        margin-top: 1rem;
        width: 50%;
        max-width: 80rem;
      }
      .images {
        width: 50%;
        height: 600px;
        margin: auto;
        position: relative;
      }
    }
  }

  @media only screen and (max-width: 670px) {
    min-height: 100vh;
    padding-bottom: 200vh;

    &.detail {
      .detail-content1 {
        line-height: 2rem;
        font-size: 1.1rem;
        flex-direction: column;
        padding: 2rem;
        .text {
          width: 100%;
          padding-bottom: 2rem;
        }
        .images {
          width: 100%;
        }
      }
    }
  }
}
