.ImagesVerticalCarousel {
  width: 100%;
  overflow-y: hidden;

  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;

  .img-carousel {
    width: 90%;
    // margin:auto;
    height: auto;
    margin-bottom: 0rem;
  }

  
}
