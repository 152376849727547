@import "../../lib/styles.scss";

.ExtraBlock {
  position: absolute;
  top: 5rem;
  right: 0;
  background: $theme-yellow;
  width: 43px;
  transition: all 0.2s ease-in-out;
  z-index: 1100;
  height: 120px;
  color: $theme-offBlack;
  // max-height: 50vh;
  color: transparent;
  &.open {
    width: 235px;
    min-height: 120px;
    height: fit-content;
    color: $theme-offBlack;
    padding-left: 2rem;
    // padding-top: .7rem;
    // padding-bottom: .7rem;
  }

  .rotated-content {
    transform: rotate(90deg);
    transform-origin: 50% 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 2.3rem;
    left: .45rem;
    z-index: 1200;
    // height: 20px;
    width:50px;
  }
  .extra-title {
    position: fixed;
    left: 0rem;
    top: 0rem;
    text-transform: uppercase;
    font-family: "Allzweck";
    transition: opacity .2s ease-in-out;
    opacity: 1;
    color: $theme-offBlack;
    &.open {
      opacity: 0;
    //   left: 8.1rem;
    //   top: 62px;
    }

  }
  .close-title {
    text-transform: uppercase;
    font-family: "Allzweck";
    position: absolute;
    left: 0rem;
    top: 2rem;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    color: $theme-offBlack;
    &.dark {
      // color: white;
    }
    &.open {
      opacity: 1;
    //   left: 7rem;
    //   top: 110px;
    }
  }
  .more {
    font-size: 2rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(1);
    padding-left: 1rem;
    opacity: 1;
    position: absolute;
    left: 4.1rem;
    top: -0.5rem;
    z-index: 1300;
    color: $theme-offBlack;
    &.open {
      opacity: 0;
    //   left: 6.1rem;
    }
    &:hover {
      transform: scale(1.2);
    }
  }
  .less {
    font-size: 2rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(1);
    padding-left: 1rem;
    opacity: 0;
    position: absolute;
    left: 4.1rem;
    top: 1.5rem;
    z-index: 1300;
    transition: all 0.2s ease-in-out;
    color: $theme-offBlack;
    
    &.open {
      opacity: 1;
    //   left: 11rem;
    //   top: 103px;
    }
    &:hover {
      transform: scale(1.2);
    }
  }

  .extra-block-text {
    // position: absolute;
    // top: 1rem;
    // left: 1rem;
    padding: 0.5rem;
    color: transparent;
    opacity: 0;
    height: 120px;
    // transition: all .2s ease-in;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 14px;
    &.open {
      opacity: 1;
      color: $theme-offBlack;
      height: fit-content;
      width: 225px;
    }
  }

  .extra-block-image {
    // position: absolute;
    // top: 1rem;
    // left: 1rem;
    padding: 0.5rem;
    color: transparent;
    opacity: 0;
    // transition: all .01s ease-in;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 14px;
    .title {
      font-size: .8rem;
    }
    img {
      display: none;
    }
    &.open {
      opacity: 1;
      color: $theme-offBlack;
      height: fit-content;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  @media only screen and (min-width:640px) and (max-width: 850px) and (orientation: landscape)  {
    max-height: unset;

    &.open {
      width: 250px;
    }
    
  }
  @media only screen and (max-width: 670px) {
    max-height: unset;
    
    &.open {
      width: 250px;
    }
    
  }
}
