.FullScreenItem {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.911);
  z-index: 1700;
  // padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 1110;
    svg {
      width: 60px;
      height: auto;
    }
  }

  .container {
    top: 0;
    left: 0;

    height: 85%;
    width: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .fullscreen-img {
      max-width: 85%;
      max-height: 85%;
      bottom: 0;
      left: 0;
      margin: auto;
      overflow: auto;
      position: fixed;
      right: 0;
      top: 0;
      -o-object-fit: contain;
      object-fit: contain;
    }
    .title {
      position: absolute;
      bottom: 2rem;
      left: 21%;
      width: 60%;
      // border-top: 1px solid rgb(202, 202, 202);
      margin-top: 0.5rem;
      padding-top: 0.25rem;
      font-size: 15px;
      line-height: 1.5rem;
      text-align: center;
      margin: auto;
      color: rgb(202, 202, 202);
    }
  }

  @media only screen and (max-width: 670px) {
    .container {
      width: 100%;
      .fullscreen-img {
        max-width: 100%;
        max-height: 100%;
      }

      .title {
        border-top: unset;
        text-align: left;
        bottom: 1rem;
        left: 1rem;
        width: 80%;
      }
    }
  }
}
