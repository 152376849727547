.CategoryView {
  position: fixed;
  top: 0;
  left: 0;
  height: 102vh;
  overflow-x: scroll;
  overflow-y: hidden;

  .centerX {
    position: absolute;
    left: 700px;
    height: 100vh;
    width: 2px;
    top: 0;
    z-index: 2000;
    // background: pink;
  }

  

  .img-lines {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0rem;
    right: 0;
    overflow: hidden;
    width: auto;
    height: 100vh;
    margin: auto;
    bottom: unset;
    &.anchor-width {
      width: 100vw;
      height: auto;
      bottom: 5rem;
      overflow-y: hidden;
    }

    &.anchor-height {
      height: 100vh;
      width: auto;
      bottom: 0rem;
    }
    img {
      width: 100%;
    }
  }

  .video-landing {
    position: absolute;
    top: 0;
    left: 0rem;
    right: 0;
    height: 100vh;
    z-index: 200;
    display: flex;
    video {
      height: 100vh;
    }
  }

  .button {
    position: absolute;
    bottom: 50px;
    left: 50px;
    background: pink;
    padding: 0.2rem;
  }

  .container {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;

    border: 2px solid var(--gs0);
    border-radius: 8px;
    height: 33vh;
  }

  @media only screen and (max-width: 670px) {
    // background: yellow;
    position: absolute;
    top: 0;
    left: 0rem;
    right: 0;
    // bottom: 0;
    overflow-x: scroll;
    width: auto;
    height: 102vh;
    overflow-y: hidden;
    video {
      height: 100vh;
      // height: calc(var(--vh, 1vh) * 100);
    }

    .img-lines {
      &.anchor-width {
        width: auto;
        height: 100vh;
        bottom: unset;
      }
    }
  }
}
