@import "../../lib/styles.scss";

$max-width-content: 40rem;

.SectionView2 {
  right: 0;
  text-align: left;
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  &.light {
    background: white;
    color: $theme-offBlack;
  }

  &.dark {
    background: $theme-offBlack;
    color: white;
  }

  &.detail {
    position: relative;
    &.fixed-section {
      padding-bottom: 250vh;
    }

    .detail-content2 {
      padding: 4rem 6rem;
      font-family: "Denmark";
      line-height: 1.6rem;
      position: absolute;
      margin: auto;
      display: flex;
      justify-content: space-between;
      font-size: 23px;
      line-height: 2.7rem;
      .text {
        width: 300px;
        z-index: 500;
        margin-top: 1rem;
        width: 45%;
        margin-left: 5%;
        &.no-img {
          width: 100%;
        }
      }
      .images {
        width: 50%;
        // width: 35%;
        height: 300px;
        position: relative;
        margin-left: 5%;
        &.zoom {
          margin-left: 15%;
        }
      }

      .ImagesZoom {
        width: 400px;
      }
    }
  }

  @media only screen and (max-width: 670px) {
    min-height: 100vh;
    height: unset;

    &.detail {
      width: 100vw;
      .detail-content2 {
        line-height: 2rem;
        font-size: 1.1rem;
        flex-direction: column;
        padding: unset;
        padding-left: unset;
        font-family: "Denmark";
        .text {
          width: 80%;
          padding-bottom: 2rem;
          padding: 2rem;
          margin-top: 0rem;
        }
        .images {
          width: 100%;
          margin-right: 10%;
          margin-top: 25%;
          &.zoom {
            margin-left: -2%;
          }
          .ImagesZoom .img-carousel {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
