.MainView {
  .down {
    position: absolute;
    bottom: 0vh;
  }

  .topic-container {
    position: absolute;
    z-index: 1200;
    transition: all .5s ease-in-out;
    overflow: hidden;
    &.open {
      top: 0 !important;
      left: 0 !important;
    }
    &.closed {
      left: 105vw;
      // top: 105vh;
    }

    // VIEW 0
    &.architect {
      top: 70%;
      left: 20%;
    }

    &.users {
      top: 50%;
      left: 50%;
    }

    &.temple {
      top: 70%;
      right: 20%;
    }

    // VIEW 1
    &.condensate {
      top: 50%;
      left: 103rem;
    }

    &.curtain {
      top: 65%;
      left: 30rem;
    }

    &.marble {
      top: 70%;
      left: 10rem;
    }

    // VIEW 2
    &.columns {
      top: 60%;
      left: 63%;
    }

    &.glass {
      top: 65%;
      left: 40%;
    }

    &.granite {
      top: 70%;
      left: 20%;
    }

    &.roof {
      top: 45%;
      left: 30%;
    }

    // VIEW 3
    &.granite-2 {
      top: 60%;
      left: 60rem;
    }

    &.sculpture {
      top: 70%;
      left: 40rem;
    }

    &.nonclicable {
      display: none;
    }

    // VIEW 4
    &.condensate {
      top: 60%;
      left: 68rem;
    }

    &.carpet {
      top: 65%;
      left: 75rem;
    }

    &.curtain-2 {
      top: 30%;
      left: 15rem;
    }

    &.furniture {
      top: 73%;
      left: 40rem;
    }

    &.roof-2 {
      top: 15%;
      left: 65rem;
    }

    &.wardrobe {
      top: 45%;
      left: 33rem;
    }
    &.condensate-2 {
      top: 25%;
      left: 85rem;
    }

    // VIEW 5
    &.depot-2 {
      top: 60%;
      left: 20rem;
    }

    &.furniture-2 {
      top: 65%;
      left: 50rem;
    }

    &.sculpture-2 {
      top: 40%;
      left: 120rem;
    }

    &.teppich {
      top: 73%;
      left: 100rem;
    }
  }

  @media screen {
    .topic-container {
      width: 100vw;
      height: 100vh;
      &.open {
        top: 0% !important;
        left: 0 !important;
      }
      &.closed {
        // VIEW 0
        &.architect {
          top: calc(70% - 50%);
          left: 20%;
        }

        &.users {
          top: calc(50% - 50%);
          left: 90%;
        }

        &.temple {
          top: calc(70% - 50%);
          left: 200%;
        }

        // VIEW 1
        &.condensate {
          top: calc(40% - 50%);
          left: 200%;
        }

        &.curtain {
          top: calc(70% - 50%);
          left: 110%;
        }

        &.marble {
          top: calc(70% - 50%);
          left: 22%;
        }

        // VIEW 2
        &.columns {
          top: calc(65% - 50%);
          left: 220%;
        }

        &.glass {
          top: calc(60% - 50%);
          left: 110%;
        }

        &.granite {
          top: calc(70% - 50%);
          left: 70%;
        }

        &.roof {
          top: calc(45% - 50%);
          left: 30%;
        }

        // VIEW 3
        &.granite-2 {
          top: calc(60% - 50%);
          left: 190%;
        }

        &.sculpture {
          top: calc(70% - 50%);
          left: 100%;
        }

        &.nonclicable {
          display: none;
        }

        // VIEW 4
        &.condensate {
          top: calc(60% - 50%);
          left: 63%;
        }

        &.carpet {
          top: calc(65% - 50%);
          left: 190%;
        }

        &.curtain-2 {
          top: calc(30% - 50%);
          left: 10%;
        }

        &.furniture {
          top: calc(73% - 50%);
          left: 105%;
        }

        &.roof-2 {
          top: calc(15% - 50%);
          left: 200%;
        }

        &.wardrobe {
          top: calc(45% - 50%);
          left: 70%;
        }

        // VIEW 5
        &.depot-2 {
          top: calc(60% - 50%);
          left: 63%;
        }

        &.furniture-2 {
          top: calc(65% - 50%);
          left: 190%;
        }

        &.sculpture-2 {
          top: calc(40% - 50%);
          left: 390%;
        }

        &.teppich {
          top: calc(73% - 50%);
          left: 250%;
        }
      }
    }
  }
}
