.DragToExploreView {
    position: absolute;
    z-index: 400;;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.363);
    display: flex;
    justify-content:center;
    align-items: center;
    transition: opacity 0.8s ease-in-out;
    opacity: 0;
    text-transform: uppercase;
    &.show {
        opacity: 1;
    }

    &.hide {
        opacity: 0;
    }
    
    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        .tip {
            margin-top: .5rem;
            font-size: .7rem;
        }
    }
    
    .content {
        width: 85%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: -5rem;
        font-size: 1.3rem;
        transition: all .6s ease-in-out;
        &.loaded {
            width: 90%;
        }
        svg {
            width: 55px;
        }
    }
}