@import "../../lib/styles.scss";

$max-width-content: 40rem;

.ImagesZoomCurtain {
  width: 40rem;
  margin:auto;
  &.vertical {
      
    .ImageWithFooter {
      
      width: 70%;
      max-width: 60rem;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  //ipad
  @media only screen and (max-width: 870px) {
    width: 90%;
    max-height: 70vh;
    margin: auto;
    &.vertical {
      
      .ImageWithFooter {
        
        width: 60%;
        height: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    
  }

  @media only screen and (max-width: 570px) {
    width: 90%;
    margin: auto;
    &.vertical {
      // margin-top: -2rem;
      width: 90%;
      // margin: auto;
      height: auto;
      .ImageWithFooter {
        width: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    
  }

  @media only screen and (min-width: 640px) and (max-width: 850px) and (orientation: landscape) {
    &.vertical {
      width: 40%;
      margin: auto;
    }
  }
}

.SectionView7 {
  width: 100%;
  margin: auto;
  text-align: left;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  &.centered {
    justify-content: center;
    align-items: center;
  }
  &.light {
    background: white;
    color: $theme-offBlack;
  }

  &.dark {
    background: $theme-offBlack;
    color: white;
  }

  .ImageWithFooter {
    max-width: 60rem;
    width: 70vw;
    margin: auto;
  }

  &.detail {
    position: relative;
    &.fixed-section {
      padding-bottom: 400vh;
    }
    .detail-content5 {
      height: 33rem;
      // padding: 4rem 3rem;
      // padding-left: 10%;
      font-family: "Denmark";
      line-height: 1.6rem;
      position: absolute;
      margin: auto;
      display: flex;
      font-size: 1.5rem;
      line-height: 2.7rem;

      .images {
        position: relative;
        height: 100%;
        width: auto;
      }
    }
  }

  &.single-img {
    .ImagesZoom {
      width: 70%;
      margin: auto;
    }
  }

  @media only screen and (min-width: 640px) and (max-width: 850px) and (orientation: landscape) {
    height: unset;
    width: 100vw;
    padding: 6rem 0;
    min-height: unset;
    display: flex;
    justify-content: center;
    align-items: center;

    &.single-img {
      // min-height: 20vh;
      padding: 1rem 0;
      .ImagesZoom {
        margin: 0;
        margin: auto;
        margin-bottom: 0 !important;
        width: 90%;

        .ImageWithFooter {
          margin-bottom: 0rem !important;
        }
      }
    }
  }

  @media only screen and (max-width: 670px) {
    height: unset;
    width: 100vw;
    padding: 6rem 0;
    min-height: unset;
    display: flex;
    justify-content: center;
    align-items: center;

    .ImageWithFooter {
      width: 90%;
    }

    &.single-img {
      min-height: 30vh;
      .ImagesZoom {
        margin: 0;
        margin: auto;
        margin-bottom: 0 !important;
        width: 90%;

        .ImageWithFooter {
          margin-bottom: 0rem !important;
        }
      }
    }
  }
}
