@import "../../lib/styles.scss";

$max-width-content: 40rem;

.SectionView4 {
  width: 100%;
  margin: auto;
  text-align: left;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .quote {
    margin-top: 3rem;
    // font-style: italic;
    font-weight: 500;
    font-size: 30px;
    .open {
      font-size: 3rem;
      color: $theme-yellow;
      // margin-left:-4rem;
      margin-right:1rem;
      svg {
        top: 4rem;
        width:50px;
        height:50px;
      }
    }
    .close {
      font-size: 3rem;
      color: $theme-yellow;
      position: relative;
      .close-icon {
        height: 1rem;
        position:relative;
      }
      svg {
        margin-left: 1rem;
        width:50px;
        height:50px;
        position:absolute;
      }
    }
    svg {
      width: 60px;
    }
  }

  .hyper-link {
    background: $theme-yellow;
    color: black;
    text-decoration: none;
  }

  .highlight1 {
    text-transform: uppercase;
    font-family: 'Allzweck';
  }

  .highlight2 {
    text-transform: uppercase;
    font-family: 'Allzweck';
    font-weight: normal;
    font-size: .9rem;
    margin-bottom: 2rem;
    margin-top: -2rem;
  }

  &.light {
    background: white;
    color: $theme-offBlack;
  }

  &.dark {
    background: $theme-offBlack;
    color: white;
  }

  .detail-content4 {
    padding: 4rem 3rem;
    padding-left: 10%;
    font-family: "Denmark";
    line-height: 1.6rem;
    font-size: 1.5rem;
    line-height: 2.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // max-width: 55rem;
    .text {
      z-index: 500;
      margin-top: 1rem;
      width: 100%;
      display: flex;
      &.row {
        flex-direction: row;
        .text-paragraph {
          min-width: 30%;
          &:first-child {
            margin-right: 7%;
          }
        }
      }
      &.column {
        flex-direction: column;
        width: 65%;
        max-width: 85rem;
        margin: auto;
        .text-paragraph {
          margin-bottom: 5%;
          &:first-child {
            margin-bottom: 5%;
          }
        }
      }
      justify-content: space-between;
    }
  }

  .images-carousel {
    height: 100vh;
    background: pink;
    width: 100%;
  }

  @media only screen and (min-width:640px) and (max-width: 850px) and (orientation: landscape)  {
    height: unset;
    min-height: unset;

    .quote {
      font-size: 1.5rem;
    }
    &.detail {
      .detail-content4 {
        width: 90%;
        margin: auto;
        padding: 4rem 0;
        padding-left: unset;
        line-height: 2rem;
        font-size: 1.1rem;
        font-size: 1.2rem;

        .text {
          &.row {
            flex-direction: column;
            .text-paragraph {
              &:first-child {
                margin-bottom: 7%;
              }
            }
          }
          &.column {
            flex-direction: column;
            width: 100%;
            margin: auto;
            .text-paragraph {
              &:first-child {
                margin-bottom: 7%;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    height: unset;
    min-height: unset;

    .quote {
      font-size: 1.5rem;
    }
    &.detail {
      .detail-content4 {
        width: 90%;
        margin: auto;
        padding: 4rem 0;
        padding-left: unset;
        line-height: 2rem;
        font-size: 1.1rem;
        font-size: 1.2rem;

        .text {
          &.row {
            flex-direction: column;
            .text-paragraph {
              &:first-child {
                margin-bottom: 7%;
              }
            }
          }
          &.column {
            flex-direction: column;
            width: 100%;
            margin: auto;
            .text-paragraph {
              &:first-child {
                margin-bottom: 7%;
              }
            }
          }
        }
      }
    }
  }
}
