@import "../../lib//styles.scss";

.NavigationView {
  position: fixed;
  // position: absolute;
  left: 105rem;
  bottom: 3rem;
  z-index: 1600;
  background: black;
  transition: all 0.5s ease-in-out;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: "Allzweck";

  &.loaded {
    width: 35vw;
    height: 100%;
    
    top: 0vh;
    bottom: 0;
    left: 65vw;
  }

  &.closed {
    width: 45vw;
    height: 100%;
    height: calc(var(--vh, 1vh) * 100);
    top: 0vh;
    left: 105vw;
  }

  // &.loaded {
  //     width: 100vw;
  //     height: 100%;
  //     height: calc(var(--vh, 1vh) * 100);
  //     bottom: 0vh;
  //     left: 0;
  // }

  // &.closed {
  //     width: 0;
  //     height: 0;
  //     left: 3rem;
  //     bottom: 3rem;
  // }

  .navigation-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1rem;
    align-items: center;
    justify-content: flex-end;
    z-index: 300;
    max-width: $max-ui-width;
    margin: auto;
    .close-arrow {
      cursor: pointer;
      svg {
        width: 25px;
        height: auto;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    &.loaded {
      width: 50vw;
      left: 50vw;
    }
  }

  @media only screen and (min-width:640px) and (max-width: 850px) and (orientation: landscape)  {
    &.loaded {
        width: 30vw;
        left: 70vw;
    }
  }

  @media only screen and (max-width: 670px) {
    &.loaded {
      width: 100vw;
      height: 100%;
      height: calc(var(--vh, 1vh) * 100);
      bottom: 0vh;
      left: 0vw;
    }

    &.closed {
      width: 100vw;
      height: 100%;
      height: calc(var(--vh, 1vh) * 100);
      bottom: 0vh;
      left: 105vw;
    }
  }
}
