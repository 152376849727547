@import "../../../lib/styles.scss";
.IndexItems {
  padding: 0 3rem;
  text-transform: uppercase;
  color: $theme-offWhite;
  max-width: $max-ui-width;
  margin: auto;

  .index-title {
    font-size: 40px;
    color: $theme-yellow;
  }

  .topic {
    display: flex;
    padding: 0.5rem 0;
    justify-content: space-between;
    position: relative;
    z-index: 1100;
    cursor: pointer;
    color: $theme-offWhite2;
    transition: color 0.3s ease-in-out;
    font-size: 20px;
    &:hover {
      color: $theme-yellow;
    }
  }

  .views-index {
    padding-top: 1rem;
    .index-view {
      display: flex;
      flex-direction: column;
      .index-view-row {
        display: flex;
        padding: 0.5rem 0;
        justify-content: space-between;
        position: relative;
        z-index: 1100;
        cursor: pointer;

        .index-view-title {
          // &:hover {
          //   color: $theme-yellow;
          //   cursor: pointer;
          // }
        }

        .arrow-extend {
          transition: all 0.3s ease-in-out;
          position: absolute;
          top: 0;
          right: 0;
          &.up {
            transform-origin: center center;
            transform: rotate(0deg);
          }

          &.down {
            transform-origin: center center;
            transform: rotate(180deg);
          }
        }
      }
    }
    .topics {
      transition: all 0.15s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &.open {
        font-size: 0.9rem;
        .topic {
          color: $theme-offWhite2;
          transition: color 0.3s ease-in-out;
        }
      }

      &.closed {
        font-size: 0rem;
        height: 0;

        .topic {
          transition: color 0.15s ease-in-out;
          height: 0px;
          padding: 0;
          font-size: 0rem;
          color: $theme-offBlack;
        }
      }

      .topic {
        position: relative;
        z-index: 1000;
        display: block;
        color: $theme-offWhite2;
        padding: 0.4rem;
        // transition: color 0.3s ease-in-out;
        &:hover {
          color: $theme-yellow;
          cursor: pointer;
        }
      }

      padding-bottom: 1rem;
    }
  }

  @media only screen and (min-width:640px) and (max-width: 850px) and (orientation: landscape)  {
    padding: 0 2rem;
    .index-title {
      font-size: 1.2rem;
    }
    .topic {
      font-size: 0.9rem;
    }
  }

  @media only screen and (max-width: 670px) {
    padding: 0 2rem;
    .index-title {
      font-size: 2.2rem;
    }

    .topic {
      font-size: 0.9rem;
    }
  }
}
