.TopicBlock {
  // font-family: 'Denmark';
  position: absolute;
  top: 0rem;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  text-transform: uppercase;


  // .anchor-height {
  //   position: absolute;
  //   top: 0;
  //   left: 0rem;
  //   right: 0;
  //   height: 100vh;
  //   width: auto;
  // }

  img {
    margin: auto;
    
  }
  .floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    // margin-left: 30px;
    margin-top: -15px;
  }

  @keyframes floating {
    from {
      transform: translate(0, 0pxz);
    }
    65% {
      transform: translate(0, 15px);
    }
    to {
      transform: translate(0, -0px);
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0rem;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: auto;
    height: 100%;
  }

  .clickable {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: all 0.3s ease-in-out;
    background: transparent !important;
    z-index: 1000;
    &.architect {
      top: 50%;
      left: 30%;
      width: 200px;
      height: 200px;
      background: pink;
    }

    &.users {
      top: 30%;
      left: 30%;
      width: 400px;
      height: 200px;
      background: green;
    }

    &.temple {
      top: 20%;
      right: 20%;
      width: 400px;
      height: 400px;
      background: red;
    }
  }

  .label {
    text-transform: uppercase !important;
    font-family: "Allzweck";
    font-size: 0.9rem;
    position: absolute;
    background: black;
    color: white;
    padding: 0.5rem;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
    width: fit-content;
    cursor: pointer;
    &:hover,
    :active {
      background: rgb(225, 244, 0);
      color: black;
      transform: scale(1.1)
    }

    // VIEW 0
    &.architect {
      top: 70%;
      left: 20%;
      z-index: 1000;
    }

    &.users {
      top: 50%;
      left: 50%;
      z-index: 1000;
    }

    &.temple {
      top: 70%;
      right: 20%;
      z-index: 1000;
    }

    // VIEW 1
    &.condensate {
      top: 50%;
      left: 93rem;
      z-index: 1000;
    }

    &.curtain {
      top: 65%;
      left: 20rem;
      z-index: 1000;
    }

    &.marble {
      top: 70%;
      left: 7rem;
      z-index: 1000;
    }

    // VIEW 2
    &.columns {
      top: 60%;
      left: 63%;
      z-index: 1000;
    }

    &.glass {
      top: 65%;
      left: 40%;
      z-index: 1000;
    }

    &.granite {
      top: 70%;
      left: 20%;
      z-index: 1000;
    }

    &.roof {
      top: 45%;
      left: 30%;
      z-index: 1000;
    }

    // VIEW 3
    &.granite-2 {
      top: 60%;
      left: 60rem;
      z-index: 1000;
    }

    &.sculpture {
      top: 70%;
      left: 40rem;
      z-index: 1000;
    }

    &.nonclicable {
      display: none;
    }

    // VIEW 4
    &.condensate {
      top: 60%;
      left: 68rem;
      z-index: 1000;
    }

    &.carpet {
      top: 65%;
      left: 75rem;
      z-index: 1000;
    }

    &.curtain-2 {
      top: 30%;
      left: 15rem;
      z-index: 1000;
    }

    &.furniture {
      top: 73%;
      left: 40rem;
      z-index: 1000;
    }

    &.roof-2 {
      top: 15%;
      left: 65rem;
      z-index: 1000;
    }

    &.wardrobe {
      top: 45%;
      left: 33rem;
      z-index: 1000;
    }
    &.condensate-2 {
      top: 25%;
      left: 75rem;
      z-index: 1000;
    }

    // VIEW 5
    &.depot-2 {
      top: 60%;
      left: 20rem;
      z-index: 1000;
    }

    &.furniture-2 {
      top: 65%;
      left: 80rem;
      z-index: 1000;
    }

    &.sculpture-2 {
      top: 40%;
      left: 80%;
      z-index: 1000;
    }

    &.carpet-2 {
      top: 73%;
      left: 100rem;
      z-index: 1000;
    }

    &.lighting {
      top: 19%;
      left: 55rem;
      z-index: 1000;
    }

    // REFURBISHMENT
    &.roof-3 {
      top: 32%;
      left: 70%;
      z-index: 1000;
    }

    &.refurbishment {
      top: 55%;
      left: 40%;
      z-index: 1000;
    }
  }

  @media only screen and (max-width: 670px) {
   
    .label {
      text-transform: uppercase !important;
      font-family: "Allzweck";
      font-size: 0.9rem;
      position: absolute;
      background: black;
      color: white;
      padding: 0.5rem;
      text-transform: capitalize;
      transition: all 0.3s ease-in-out;
      width: fit-content;
      &:hover,
      :active {
        background: rgb(225, 244, 0);
        color: black;
      }

      

      // VIEW 0
      &.architect {
        top: 65%;
        left: 30%;
        z-index: 1000;
      }

      &.users {
        // top: 50%;
        // left: 90%;
        // z-index: 1000;
        display: none;
      }

      &.temple {
        top: 60%;
        left: 200%;
        z-index: 1000;
      }

      // VIEW 1
      &.condensate {
        top: 50%;
        left: 200%;
        z-index: 1000;
      }

      &.curtain {
        top: 55%;
        left: 85%;
        z-index: 1000;
      }

      &.marble {
        top: 63%;
        left: 22%;
        z-index: 1000;
      }

      // VIEW 2
      &.columns {
        top: 65%;
        left: 190%;
        z-index: 1000;
      }

      &.glass {
        top: 55%;
        left: 110%;
        z-index: 1000;
      }

      &.granite {
        top: 65%;
        left: 70%;
        z-index: 1000;
      }

      &.roof {
        top: 45%;
        left: 30%;
        z-index: 1000;
      }

      // VIEW 3
      &.granite-2 {
        top: 60%;
        left: 150%;
        z-index: 1000;
      }

      &.sculpture {
        top: 63%;
        left: 50%;
        z-index: 1000;
      }

      &.nonclicable {
        display: none;
      }

      // VIEW 4
      &.condensate-2 {
        top: 40%;
        left: 213%;
        z-index: 1000;
      }

      &.carpet {
        top: 65%;
        left: 190%;
        z-index: 1000;
      }

      &.curtain-2 {
        top: 30%;
        left: 10%;
        z-index: 1000;
      }

      &.furniture {
        top: 60%;
        left: 135%;
        z-index: 1000;
      }

      &.roof-2 {
        top: 15%;
        left: 200%;
        z-index: 1000;
      }

      &.wardrobe {
        top: 45%;
        left: 70%;
        z-index: 1000;
      }

      // VIEW 5
      &.depot-2 {
        top: 60%;
        left: 63%;
        z-index: 1000;
      }

      &.furniture-2 {
        top: 65%;
        left: 225%;
        z-index: 1000;
      }

      &.sculpture-2 {
        top: 45%;
        left: 205%;
        z-index: 1000;
      }

      &.carpet-2 {
        top: 73%;
        left: 250%;
        z-index: 1000;
      }

      // REFURBISHMENT
      &.roof-3 {
        top: 32%;
        left: 140%;
        z-index: 1000;
      }

      &.refurbishment {
        top: 60%;
        left: 40%;
        z-index: 1000;
      }

      &.lighting {
        top: 19%;
        left: 35rem;
        z-index: 1000;
      }
    }
  }
}
