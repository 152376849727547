
.ImagesZoom {
  height:100%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 10%;
  &.static {
    .ImageWithFooter {
      height: unset ;
      .footer-text {
        font-size: 1rem;
        // line-height: 2rem;
      }
    }
  }
  &.zoom {
    .ImageWithFooter {
      height: unset ;
      .footer-text {
        font-size: 1.8rem;
        line-height: 2rem;
      }
    }
  }
  
  .img-carousel {
    height: 100%;
    // width: auto;
    // margin-bottom: 3rem;
  }

  .video-asset {
    height: 100%;
    width: auto;
  }



  

  @media only screen and (min-width:640px) and (max-width: 850px) and (orientation: landscape)  {
    margin-top:unset;
    width: 90%;
    margin: auto;
    // margin-bottom: -8rem;
    .img-carousel {
     
      // height: auto;
      // margin-bottom: 3rem;
    }
  
    .video-asset {
      width: 100%;
      height: auto;
      margin-bottom: 3rem;
      margin: auto;
    }
  }

  @media only screen and (max-width: 670px) {
    margin-top:unset;
    width: 90%;
    margin: auto;
    // margin-bottom: -8rem;
    .img-carousel {
     
      // height: auto;
      margin-bottom: 3rem;
    }
  
    .video-asset {
      width: 100%;
      height: auto;
      margin-bottom: 3rem;
      margin: auto;
    }
  }
}
