.ImageWithFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2rem;
  width: auto;
  height: 100%;
  img {
    width: 100%;
    height: auto;
  }
  video {
    width: 100%;
    height: auto;
    max-width: 70vw;
  }
  .footer-text {
    width: 60%;
    border-top: 1px solid rgb(202, 202, 202);
    margin-top: 1rem;
    padding-top: 0.25rem;
    color: gray;
    font-size: 15px;
    line-height: 1.5rem;
    transform: scale(1) !important;
  }

  @media only screen and (min-width: 640px) and (max-width: 850px) and (orientation: landscape) {
    // img {
    width: 60%;
    margin: auto;
    // }

    .footer-text {
      width: 100%;
      font-size: 10px;
      line-height: 12px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
    }
  }
  @media only screen and (max-width: 800px) {
    margin-bottom: 0rem;
    margin-top: -1rem;

    video {
      max-width: unset !important;
    }
    .footer-text {
      font-size: 11px;
      line-height: 15px;
    }
  }

  @media only screen and (max-width: 400px) {
    video {
      max-width: unset;
    }
  }
}
