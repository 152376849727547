@font-face {
  font-family: "Allzweck";
  src: local("Allzweck"), url(./assets/fonts/Allzweck.otf) format("opentype");
}
@font-face {
  font-family: "Denmark";
  src: local("Denmark"), url(./assets/fonts/Denmark.otf) format("opentype");
}
@font-face {
  font-family: "DenmarkBold";
  src: local("DenmarkBold"),
    url(./assets/fonts/DenmarkBold.otf) format("opentype");
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&family=Open+Sans:wght@300;400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

html,
body {
  // height: 100%;
  // overflow: hidden;
  font-family: "Denmark";
  @include scrollbars(0px, transparent, transparent);
}
.App::-webkit-scrollbar { width: 0 !important }
// .App { overflow: -moz-scrollbars-none; }
// .App { -ms-overflow-style: none; }
.App {
  text-align: center;
  font-family: "Allzweck";
  // overflow: unset !important;
  // overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  
  input {
    border: 0px solid transparent;
    background: white;
    padding: 0.7rem;
    font-size: 1rem;
  }

  .btn-primary {
    width: 150px;
    background: rgb(152, 152, 155);
    padding: 0.7rem;
    color: white;
    font-size: 1rem;
    font-weight: 500;
  }
}

.gradient-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 13rem;
  z-index: 50;
  //     background: rgb(117,117,117);
  // background: linear-gradient(180deg, rgba(117,117,117,0.5480567226890756) 0%, rgba(194,194,194,0.4332107843137255) 35%, rgba(238,237,237,0) 72%, rgba(255,255,255,0) 100%);
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6550271739130435) 0%,
    rgba(0, 0, 0, 0.423143115942029) 22%,
    rgba(0, 0, 0, 0.22314311594202896) 53%,
    rgba(0, 0, 0, 0) 100%
  );
}
.gradient-bottom {
  position: fixed;
  bottom: 0;
  z-index: 50;
  left: 0;
  right: 0;
  height: 10rem;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.28755252100840334) 49%,
    rgba(0, 0, 0, 0.4724264705882353) 70%,
    rgba(0, 0, 0, 0.6685049019607843) 100%
  );
}

.bigger {
  // font-size: 1.1rem;
}

@media only screen and (max-width: 375px) {
  .gradient-top {
    opacity: 1;
    transition: opacity .5s ease-in;
    height: 9rem;
  }
  .gradient-bottom {
    opacity: 1;
    transition: opacity .5s ease-in;
    height: 9rem;
  }
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.loading-anim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Allzweck";
  font-weight: 200;
  text-transform: uppercase;

  .progress-bar-container {
    width: 220px;
    height: 3px;
    background: rgba(248, 248, 248, 0.205);
    margin-top: 1rem;

    .progress-bar {
      height: 100%;
      background: white;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
