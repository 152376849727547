.Section5_ {
  position: relative;
  width: 100%;
  min-height: 100vh;
  img {
      height: 90%;
      width: auto;
  }
  .HorizontalImageArray {
    position: relative;
    height: 100%;
    padding: 0 0 0 150px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .footer-text {
    width: 60%;
    border-top: 1px solid rgb(202, 202, 202);
    margin-top: 1rem;
    padding-top: 0.25rem;
    color: gray;
    font-size: 15px;
    line-height: 1.5rem;
    transform: scale(1) !important;
  }
  
}

.ImageWithFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 2rem;
    width: auto;
    height: 100%;
    img {
      width: 100%;
      height: auto;
    }
    video {
      width: 100%;
      height: auto;
      max-width: 70vw;
    }
    .footer-text {
      width: 60%;
      border-top: 1px solid rgb(202, 202, 202);
      margin-top: 1rem;
      padding-top: 0.25rem;
      color: gray;
      font-size: 15px;
      line-height: 1.5rem;
      transform: scale(1) !important;
    }
}
