.CollageView {
    margin: auto;
    img {
        top: 0;
    }
    .collage {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

