@import "../../lib/styles.scss";

$max-width-content: 100rem;
.DetailTitle {
  &.refurbishment {
    background: $theme-purple;
  }

  .refurbishment {
    font-family: 'Denmark';
    font-size:30px;
    position: absolute;
    top: 5vw;
    left: 10%;
    // padding-left: 2%;
  }

  width: 100%;
  margin: auto;
  text-align: left;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background: $theme-yellow;
  color: black;
  font-size: 2.1rem;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Allzweck" !important;
  font-size: 6rem;
  .number {
    font-size: 7rem;
  }
  .detail-title-content {
    text-align: left;
    z-index: 200;
    text-transform: uppercase;
    padding: 1rem;
    max-width: $max-width-content * 2;
    width: 80%;
  }

  @media only screen and (min-width:640px) and (max-width: 850px) and (orientation: landscape)  {
    font-size: 2rem;
    padding-left: 1.5rem;
    align-items: flex-start;
    .refurbishment { 
      top: 20%;
      font-size: 1.2rem;
      left: 1.5rem;
    }
    .number {
      font-size: 3rem;
    }
    
    .detail-title-content {
      margin: unset;
      // width: 100%;
      padding-right: 2rem;
      padding: 0;
    }
  }

  @media only screen and (max-width: 1000px) {
    font-size: 4.5rem;
  }

  @media only screen and (max-width: 800px) {
    font-size:2rem;
    padding-left: 1.5rem;
    align-items: flex-start;
    .refurbishment { 
      top: 20%;
      font-size: 1.9rem;
      left: 1.5rem;
    }
    .number {
      font-size: 5rem;
    }
  }
  @media only screen and (max-width: 670px) {
    font-size: 1.8rem;
    padding-left: 1.5rem;
    align-items: flex-start;
    .refurbishment { 
      top: 20%;
      font-size: 1.2rem;
      left: 1.5rem;
    }
    .number {
      font-size: 3rem;
    }
    
    .detail-title-content {
      margin: unset;
      // width: 100%;
      padding-right: 2rem;
      padding: 0;
      .text {
      }
    }
  }
}
