.MenuLangSwitch {
    .menu-button {
        position: fixed;
        top: 0rem;
        right: 0;
        z-index: 1500;
        cursor: pointer;
        transform: scale(1);
        transition: all 0.2s ease-out;
        &:hover {
          // transform: scale((1.1));
        }
        svg {
          width: 60px;
          height: auto;
        }
      }

    .language-switch {
        position: fixed;
        top: 0rem;
        right: 3rem;
        z-index: 1500;
        cursor: pointer;
        transform: scale(1);
        transition: all 0.2s ease-out;
        background: black;
        width: 80px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Allzweck';
        &:hover {
          // transform: scale((1.1));
        }
        .selected-language {
            font-size: 1.5rem;
            color: white;
        }
    } 
}